// check if the agent is android or ios or web
export const getAgent = (
  userAgent: string
): 'android' | 'ios' | 'web' | 'unknown' => {
  const ua = userAgent.toLowerCase()

  if (ua.includes('android')) {
    return 'android'
  } else if (
    ua.includes('iphone') ||
    ua.includes('ipad') ||
    (ua.includes('macintosh') && ua.includes('mobile')) // iPadOS 13+
  ) {
    return 'ios'
  } else if (typeof window !== 'undefined') {
    return 'web'
  } else {
    return 'unknown'
  }
}
